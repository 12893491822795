import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import todaysDate from "../bin/todaysDate";
import epochConverterToEST from "../bin/epochConverterToEST";
import dontRerender from "../bin/dontRerender";
import exchanges from "../bin/exchanges";
import abbrInt from "../bin/abbrInt";
import Headers from "../bin/Headers";
import compileTradeDetailForModal from "../bin/compileTradeDetailForModal";
import LOADING from "../components/LOADING";
import ECHARTNOTIONAL from "../components/ECHARTNOTIONAL";
import MODAL from "../components/MODAL";
import { PowerIcon, BanknotesIcon, BookOpenIcon, ClockIcon, BeakerIcon, ChevronDownIcon, XMarkIcon, StarIcon, ArrowPathIcon, ScaleIcon, ListBulletIcon } from "@heroicons/react/24/outline";

const MAIN = (props) => {
  const ExchangeMap = {
    "1": "AMEX",
    "2": "XBOS",
    "3": "XCIS",
    "4": "DARK",
    "5": "NASDAQ",
    "6": "XISE",
    "7": "EDGA",
    "8": "EDGX",
    "9": "CHICAGO",
    "10": "NYSE",
    "11": "ARCA",
    "12": "NASDAQ",
    "13": "NYSE",
    "14": "LTSE",
    "15": "IEX",
    "16": "CBSX",
    "17": "PHILLY",
    "18": "BATS",
    "19": "BATS",
    "20": "EPRL",
    "21": "MEMX",
  };
  let token = useSelector((state) => state.token),
    ticker = props.ticker,
    last = props.last,
    orderBook = props.orderBook,
    tickerStats = props.tickerStats,
    passedorders = props.orders,
    passedOrderType = props.orderType,
    _orders = useRef([]),
    _candles = useRef([]),
    _averageLargeTrade = useRef(0),
    _latestTradeDate = useRef(null),
    _openTrade = useRef(0),
    _sort = useRef("value"),
    refreshL3Quotes = useRef(null),
    loading = props.loading,
    [showOptionSentiment, setShowOptionSentiment] = useState(false),
    [orderBookUnavailable, updateOrderBookUnavailable] = useState(true),
    [showPriceLevels, setShowPriceLevels] = useState(false),
    [showOrderBook, setShowOrderBook] = useState(false),
    [loadingCandles, setLoadingCandles] = useState(false),
    [l3nbbo, setL3nbbo] = useState(false),
    [l3NbboOrders, setL3NbboOrders] = useState(null),
    [delayedData, setDelayedData] = useState(false),
    [sort, setSort] = useState("value"),
    [orders, setOrders] = useState([]),
    [candles, updateCandles] = useState([]),
    [showTradeInfo, updateShowTradeInfo] = useState(false),
    [focusedTrade, updateFocusedTrade] = useState(null),
    [tradeDetail, updateTradeDetail] = useState(null);

  const orderEntryUi = (item, index) => {
    let side = null;
    let bgColor = `bg-gray-950${item.Market !== "OPEN" && !item.ClosingTrade && !item.OpeningTrade ? "/50" : ""}`;

    if (passedOrderType === "Bid") {
      side = "Bid";
      bgColor = `bg-emerald-950${item.Market !== "OPEN" && !item.ClosingTrade && !item.OpeningTrade ? "/50" : ""}`;
    } else if (passedOrderType === "Ask") {
      side = "Ask";
      bgColor = `bg-rose-950${item.Market !== "OPEN" && !item.ClosingTrade && !item.OpeningTrade ? "/50" : ""}`;
    } else if (passedOrderType) {
      side = "Mid";
    }

    return (
      // <button
      //   key={item.TradeKey}
      //   className={`flex flex-row w-full text-xxs sm:text-xs text-gray-200 ${bgColor} ${focusedTrade === null ? "block" : focusedTrade && focusedTrade.TradeKey && focusedTrade.TradeKey === item.TradeKey ? "block" : "hidden"}`}
      //   onClick={() => {
      //     //if already focused, then pop open the detail
      //     if (focusedTrade && focusedTrade.TradeKey && focusedTrade.TradeKey === item.TradeKey) {
      //       modalTradeDetail(item);
      //     }
      //     updateSorting("graph");
      //     updateFocusedTrade(item);
      //   }}
      // >
      <span
        key={item.TradeKey}
        className={`flex flex-row w-full text-xxs sm:text-xs text-gray-200 ${bgColor} ${focusedTrade === null ? "block" : focusedTrade && focusedTrade.TradeKey && focusedTrade.TradeKey === item.TradeKey ? "block" : "hidden"}`}
      >
        <span className={`${!ticker ? "hidden" : "flex"} p-1 items-center justify-center font-light w-12`}>#{item.Rank}</span>
        <span className={`hidden p-1 items-center justify-center w-20 ${!ticker ? "sm:flex" : ""}`}>{item.Ticker}</span>
        <span className={`p-1 flex items-center justify-start sm:justify-center flex-grow `}>
          <span className="hidden sm:block xl:hidden">{item && item.Size ? item.Size.toLocaleString() : '-'} @ ${item && item.Price ? item.Price.toLocaleString() : '-'}</span>
          <span className="hidden xl:block">{item && item.Size ? item.Size.toLocaleString() : '-'} @ ${item && item.Price ? item.Price.toLocaleString() : '-'}</span>
          <span className="flex flex-col w-full items-start justify-start sm:hidden">
            <span className="flex flex-row w-full items-start justify-start gap-1">
              <span className={`text-left font-bold ${!ticker ? "inline" : "hidden"} w-14`}>{item.Ticker}</span>
            </span>
            <span className={`flex flex-row w-full items-start justify-between gap-1`}>
              <span className="inline-block text-left w-full">
                {item && item.Size ? item.Size.toLocaleString() : '-'} @ ${item && item.Price ? Number(item.Price.toFixed(2)).toFixed(2).toLocaleString() : '-'}
              </span>
              <span className="inline-block text-right w-full">
                <span className="font-light text-gray-400 w-100 text-left text-[8px]">Value:</span>
                {/* <span className={`ml-1 font-light w-100 text-center`}>${abbrInt(item.Value, true, true)}</span> */}
                <span className={`ml-1 font-light w-100 text-center`}>${item && item.Value ? (item.Value).toLocaleString() : '-'}</span>
              </span>
            </span>
            <span className="flex flex-row w-full items-start justify-between gap-1">
              <span className={`inline-block text-left w-full ${item.Exchange === "DARK" ? "bg-gray-900/50" : ""}`}>
                <span className="font-light text-gray-400 w-100 text-left text-[8px]">Exchange:</span>
                <span className="ml-1 font-light w-100 text-center text-rose-400">{item.Exchange}</span>
              </span>
              <span className="inline-block text-right w-full">
                <span className="font-light text-gray-400 w-100 text-left text-[8px]">Time:</span>
                <span className={`ml-1 font-light w-100 text-center text-gray-400`}>{item.EntryTimeString}</span>
              </span>
            </span>
          </span>
        </span>
        {/* <span className={`hidden sm:flex p-1 items-center justify-center w-32`}>${abbrInt(item.Value, true, true)}</span> */}
        <span className={`hidden sm:flex p-1 items-center justify-center w-32`}>${item && item.Value ? (item.Value).toLocaleString() : '-'}</span>
        <span className={`hidden sm:flex p-1 items-center justify-center w-24 ${item.Exchange === "DARK" ? "bg-gray-900/50" : "text-amber-300"}`}>{item.Exchange}</span>
        <span className={`hidden sm:flex p-1 items-center justify-end text-xxs w-24`}>{item.EntryTimeString}</span>
      {/* </button> */}
      </span>
    );
  };

  const sortOrders = () => {
    if (!_orders.current || !_orders.current.length) {
      return;
    }
    let _ordersArray = [..._orders.current];
    let __sort = _sort.current;
    _ordersArray.sort((a, b) => b.Value - a.Value);
    _ordersArray = _ordersArray
      .filter((entry) => typeof entry === "object")
      .map((entry, index) => {
        entry.Rank = index + 1;
        return entry;
      });
    if (__sort === "time") {
      _ordersArray.sort((a, b) => {
        const timeA = Number(a.EntryTimestamp);
        const timeB = Number(b.EntryTimestamp);

        // Check if both timestamps are valid numbers
        if (!isNaN(timeA) && !isNaN(timeB)) {
          return timeB - timeA;
        } else {
          // Handle invalid timestamps here (e.g., return 0 or some default value)
          return 0;
        }
      });
    }
    if (__sort === "value") {
      _ordersArray.sort((a, b) => b.Value - a.Value);
    }
    _orders.current = _ordersArray;
    setOrders(_orders.current);
  };

  const modalTradeDetail = (trade) => {
    if (!trade) {
      return;
    }
    // console.log(trade);
    let exchange = trade.Exchange ? trade.Exchange : null;
    if (exchange === "XADF") {
      exchange = "DARK POOL";
    } else if (exchange === "XNYS") {
      exchange = "NYSE";
    } else if (exchange === "XNAS") {
      exchange = "NASDAQ";
    } else if (exchange === "ARCX") {
      exchange = "NYSE ARCA";
    } else if (exchange === "BATS") {
      exchange = "BATS";
    } else if (exchange === "XCHI") {
      exchange = "NYSE CHICAGO";
    } else if (exchange === "XPHL") {
      exchange = "NASDAQ PHILLY";
    } else if (exchange === "XASE") {
      exchange = "NYSE AMEX";
    } else if (exchange === "XOTC") {
      exchange = "OTC";
    } else if (exchange === "XCBO") {
      exchange = "CBOE";
    } else if (exchange === "IEXG") {
      exchange = "IEX";
    } else if (exchange === "MEMX") {
      exchange = "MEMX";
    }
    let beforeQuoteTime = null;
    let afterQuoteTime = null;
    if (trade.QuoteTimestampBeforeTrade) {
      beforeQuoteTime = epochConverterToEST(trade.QuoteTimestampBeforeTrade);
      beforeQuoteTime = `${beforeQuoteTime.Hour}:${beforeQuoteTime.Minute}:${beforeQuoteTime.Second}.${beforeQuoteTime.Millisecond}`;
    }
    if (trade.QuoteTimestampAfterTrade) {
      afterQuoteTime = epochConverterToEST(trade.QuoteTimestampAfterTrade);
      afterQuoteTime = `${afterQuoteTime.Hour}:${afterQuoteTime.Minute}:${afterQuoteTime.Second}.${afterQuoteTime.Millisecond}`;
    }
    let _tradeDetail = compileTradeDetailForModal({
      ticker: trade.ticker ? trade.ticker : null,
      name: trade.name ? trade.name : null,
      time: trade.EntryTimestamp ? trade.EntryTimestamp : null,
      qyt: trade.Size ? trade.Size : null,
      price: trade.Price ? trade.Price : null,
      value: trade.Notional ? trade.Notional : null,
      ex: exchange ? exchange : null,
      side: trade.OrderSide ? trade.OrderSide : null,
      flags: trade.Conditions ? trade.Conditions : null,
      beforeQuoteTime,
      bidBeforeTrade: trade.BidBeforeTrade ? trade.BidBeforeTrade : null,
      midBeforeTrade: trade.MidBeforeTrade ? trade.MidBeforeTrade : null,
      askBeforeTrade: trade.AskBeforeTrade ? trade.AskBeforeTrade : null,
      afterQuoteTime,
      bidAfterTrade: trade.BidAfterTrade ? trade.BidAfterTrade : null,
      midAfterTrade: trade.MidAfterTrade ? trade.MidAfterTrade : null,
      askAfterTrade: trade.AskAfterTrade ? trade.AskAfterTrade : null,
    });
    updateTradeDetail(_tradeDetail);
    updateShowTradeInfo(true);
  };

  const updateSorting = (sorting) => {
    setSort(sorting);
    _sort.current = sorting;
    sortOrders();
  };

  const checkIfDelayedData = () => {
    let tradeDate = passedorders && passedorders.length ? passedorders[0].EntryDateString : null;
    if (tradeDate && tradeDate !== todaysDate()) {
      setDelayedData(tradeDate);
    }
  };

  const readCandlesP3 = async (latestTradeDate) => {
    if (!latestTradeDate && _latestTradeDate.current) {
      latestTradeDate = _latestTradeDate.current;
    }
    setLoadingCandles(true);
    // const response = await fetch(`https://io.redstripedtie.com/ursa/candles_p3?ticker=${ticker}&format=minute&startDate=${latestTradeDate ? latestTradeDate : todaysDate()}&endDate=${latestTradeDate ? latestTradeDate : todaysDate()}`, { headers: Headers(token) }).then((d) => d.json());
    const response = await fetch(`https://api.redstripedtie.com/candles_p3?ticker=${ticker}&format=minute&startDate=${latestTradeDate ? latestTradeDate : todaysDate()}&endDate=${latestTradeDate ? latestTradeDate : todaysDate()}`, { headers: Headers(token) }).then((d) => d.json());
    if (!response || response.message !== "ok" || !response.result) {
      setLoadingCandles(false);
      return;
    }
    setLoadingCandles(false);

    _candles.current = response.result;
    updateCandles(_candles.current);
  };

  const pullRankQuotes = async () => {
    try {
      const response = await fetch(`https://api.redstripedtie.com/rankquotes?ticker=${ticker}`, { headers: Headers(token) }).then((d) => d.json());
      if (!response || response.message !== "ok" || !response.result) {
        if (passedOrderType==='Bid' && response.result.bestBidsByValue) { setL3NbboOrders(response.result.bestBidsByValue); }
        if (passedOrderType==='Ask' && response.result.bestAsksByValue) { setL3NbboOrders(response.result.bestAsksByValue); }
        return;
      }
    }catch(e){ }
  };

  const toggleL3Nbbo = async () => {
    if (refreshL3Quotes.current) {
      setL3nbbo(false);
      clearInterval(refreshL3Quotes.current);
      refreshL3Quotes.current = null;
      setL3NbboOrders(null);
      return;
    }
    setL3nbbo(true);
    refreshL3Quotes.current = setInterval(pullRankQuotes(), 10 * 1000);
  };

  useEffect(() => {
    checkIfDelayedData();
    if (!passedorders || !passedorders.length) { return; }
    if (passedOrderType === 'Bid') {
      passedorders = passedorders.filter(order => order.BidPrice!==null || order.AskPrice!==null).map(order => {
        let d = epochConverterToEST(order.QuoteUpdated);
        let EntryDateString = `${d.Year}-${d.Month}-${d.Date}`;
        let EntryTimeString = `${d.Hour}:${d.Minute}:${d.Second}.${d.Millisecond}`;
        return { EntryTimestamp: order.QuoteUpdated, EntryDateString, EntryTimeString, Exchange: ExchangeMap[order.BidExchange], Price: order.BidPrice, Size: order.BidSize, Value: order.BidValue };
      }).sort((a,b) => b.Value - a.Value);
    }
    if (passedOrderType === 'Ask') {
      passedorders = passedorders.map(order => {
        let d = epochConverterToEST(order.QuoteUpdated);
        let EntryDateString = `${d.Year}-${d.Month}-${d.Date}`;
        let EntryTimeString = `${d.Hour}:${d.Minute}:${d.Second}.${d.Millisecond}`;
        return { EntryTimestamp: order.QuoteUpdated, EntryDateString, EntryTimeString, Exchange: ExchangeMap[order.AskExchange], Price: order.AskPrice, Size: order.AskSize, Value: order.AskValue };
      }).sort((a,b) => b.Value - a.Value);
    }
    _orders.current = passedorders;
    let latestTradeDate = orders && orders.length ? orders[0].EntryDateString : null;
    if (latestTradeDate) { _latestTradeDate.current = latestTradeDate; }
    sortOrders();
  }, [passedorders, sort]);

  return (
    <div className={`w-full h-full flex flex-col rounded-tr-md rounded-md border bg-gray-200 text-black`}>
      <div className="border-gray-300 rounded-t-lg w-full text-center text-semibold flex flex-col justify-center items-start px-1 py-0.5">
        <span className="flex flex-row items-center justify-end w-full">
          {delayedData ? (
            <Link to="/pricing" className="flex flex-row items-start justify-start"><span className="text-xs text-rose-600">Orders from {delayedData}</span></Link>
          ) : (
            <></>
          )}
          {!loading ? (
            <span className="flex-col inline-flex items-end justify-end flex-grow sm:items-center gap-1">
              <span className={`${focusedTrade === null ? "hidden" : ""} inline-flex w-full justify-between items-center gap-1`}>
                {!delayedData ? (
                  <button type="button" onClick={() => { readCandlesP3();}}className={`rounded px-2 py-0.5 text-xs font-semibold border border-gray-700 text-white bg-emerald-600`}>
                    <ArrowPathIcon className="w-5 h-4" />
                  </button>
                ) : (
                  <span></span>
                )}
                <button type="button" onClick={() => { updateFocusedTrade(null); updateSorting("value"); }} className={`rounded px-2 py-0.5 text-xs font-semibold border border-gray-700 text-white bg-rose-600`}>
                  <XMarkIcon className="w-5 h-4" />
                </button>
              </span>
              <span className={`${focusedTrade !== null ? "hidden" : ""} inline-flex w-full justify-end items-center gap-1`}>
                <button type="button" onClick={() => { updateFocusedTrade(null); updateSorting(sort === "value" ? "time" : "value"); }} className={`rounded px-2 py-0.5 text-xs font-semibold border ${sort === "value" ? "border-gray-700 text-white bg-emerald-600" : "border-gray-500 text-gray-300 bg-slate-700"}`}>
                  <BanknotesIcon className="w-5 h-4" />
                </button>
                <button type="button" onClick={() => { updateFocusedTrade(null); updateSorting(sort === "time" ? "value" : "time"); }} className={`rounded px-2 py-0.5 text-xs font-semibold border ${sort === "time" ? "border-gray-700 text-white bg-emerald-600" : "border-gray-500 text-gray-300 bg-slate-700"}`}>
                  <ClockIcon className="w-5 h-4" />
                </button>
                <button type="button" onClick={() => { updateFocusedTrade(null); toggleL3Nbbo(); }} className={`${!['GME', 'IWM', 'SPY', 'QQQ'].includes(ticker) ? 'hidden' : ''} rounded px-2 py-0.5 text-xs font-semibold border ${l3nbbo === true ? "border-gray-700 text-rose-500 bg-black" : "border-gray-500 text-gray-300 bg-slate-700"}`}>
                  <PowerIcon className="w-5 h-4" />
                </button>
              </span>
            </span>
          ) : (
            <></>
          )}
        </span>
      </div>

      <div className="col-span-2 flex flex-grow flex-col rounded-br-md rounded-bl-md border bg-gray-700">
        {loading ? (
          <div className="h-48 flex items-center justify-center">
            <LOADING color="text-green-500" />
          </div>
        ) : (
          <></>
        )}
        {!loading && l3nbbo && (!orders || !orders.length) ? <h6 className="my-5 text-gray-400 text-center">No Orders</h6> : <></>}
        {!loading && !l3nbbo && (!orders || !orders.length) ? <h6 className="my-5 text-gray-400 text-center">No Orders</h6> : <></>}
        {/* {!loading && sort!=='graph' && orders && orders.length ? */}
        {!loading && !l3nbbo && orders && orders.length ? (
          <div className={`${focusedTrade === null ? "h-full" : ""} w-full`}>
            <div className="flex flex-row w-full text-xxs sm:text-xs text-gray-200">
              <span className={`p-1 items-center justify-center border uppercase font-medium tracking-wider w-12 ${!ticker ? "hidden" : "flex"}`}>Rank</span>
              <span className={`hidden p-1 items-center justify-center border font-medium uppercase tracking-wider w-20 ${!ticker ? "sm:flex" : ""}`}>Ticker</span>
              <span className="p-1 flex items-center justify-center border font-medium uppercase tracking-wider flex-grow">Order</span>
              <span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-32">Value{sort === "value" ? <ChevronDownIcon className="w-5 h-4" /> : <></>}</span>
              <span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-24">Exchange</span>
              <span className="hidden sm:flex p-1 items-center justify-end border font-medium uppercase tracking-wider w-24">Time{sort === "time" ? <ChevronDownIcon className="w-5 h-4" /> : <></>}</span>
            </div>

            {orders.map((item, index) => orderEntryUi(item, index))}
          </div>
        ) : (
          <></>
        )}
        {/* {!loading && sort === 'graph' && orders && orders.length && candles && candles.length ? */}
        {!loading && sort === "graph" && orders && orders.length ? (
          <div className="bg-gradient-to-r from-sky-950 to-purple-950 w-full flex flex-col max-h-[280px] sm:max-h-full h-full min-h-24 items-center justify-center">
            <ECHARTNOTIONAL candles={candles} last={last} timing="minute" marker={focusedTrade} loading={loadingCandles} orderBook={orderBook} showOrderBook={showOrderBook} tickerStats={tickerStats} showPriceLevels={showPriceLevels} />
          </div>
        ) : (
          <></>
        )}

        {!loading && sort === "graph" && orders && orders.length ? (
          <div className="border bg-gray-200 text-black w-full flex flex-row items-center justify-between rounded-b-md">
            <div className="flex flex-row gap-0.5 items-center w-full justify-start">
              <button type="button" onClick={() => setShowPriceLevels(!showPriceLevels)} className={`rounded px-2 py-0.5 text-xs font-semibold border ${showPriceLevels ? "border-white text-white bg-emerald-600" : "border-gray-500 text-gray-300 bg-slate-700"}`}>
                <span className="flex sm:hidden">
                  <ListBulletIcon className="w-5 h-4" />
                </span>
                <span className="hidden sm:flex">Price Levels</span>
              </button>
              <button type="button" disabled={orderBookUnavailable} onClick={() => setShowOrderBook(!showOrderBook)} className={`rounded px-2 py-0.5 text-xs font-semibold border ${showOrderBook ? "border-white text-white bg-emerald-600" : !orderBookUnavailable ? "border-gray-500 text-gray-300 bg-slate-700" : "border-gray-500/50 text-gray-300/50 bg-slate-700/50"}`}>
                <span className="flex sm:hidden">
                  <BookOpenIcon className="w-5 h-4" />
                </span>
                <span className="hidden sm:flex">Order Book</span>
              </button>
              {/* <button
								type="button"
								disabled={optionSentimentUnavailable}
								onClick={() => toggleOptionSentiment()}
								className={`rounded px-2 py-0.5 text-xs font-semibold border ${showOptionSentiment ? 'border-white text-white bg-emerald-600' : (!optionSentimentUnavailable ? 'border-gray-500 text-gray-300 bg-slate-700' : 'border-gray-500/50 text-gray-300/50 bg-slate-700/50')}`}>
								<span className='flex sm:hidden'><ScaleIcon className='w-5 h-4' /></span>
								<span className='hidden sm:flex'>Option Sentiment</span>
							</button> */}
            </div>

            <div className={`flex flex-row gap-0.5 items-center w-full justify-end ${focusedTrade !== null ? "" : "hidden"}`}>
              <button
                type="button"
                onClick={() => {
                  modalTradeDetail(focusedTrade);
                }}
                className={`rounded px-2 py-0.5 text-xs font-semibold border border-gray-500 text-gray-300 bg-slate-700`}
              >
                Trade Detail
              </button>
              {/* <button
								type="button"
								onClick={() => { modalTradeDetail(focusedTrade); }}
								className={`${focusedTrade!==null ? '' : 'hidden'} rounded px-2 py-0.5 text-xs font-semibold border border-gray-500 text-gray-300 bg-slate-700`}>
								<StarIcon className='w-5 h-4' />
							</button> */}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <MODAL
        open={showTradeInfo}
        setOpen={() => {
          updateShowTradeInfo(false);
          updateTradeDetail(null);
        }}
        headline="Trade Detail [stock]"
        html_information={true}
        information={tradeDetail}
      />
    </div>
  );
};

// export default MAIN;
export default React.memo(MAIN, dontRerender);
