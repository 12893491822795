import React, { useEffect, useState, useRef } from 'react';
import Headers from "../bin/Headers";
import dontRerender from '../bin/dontRerender';
import truncateToTwoDigits from '../bin/truncateToTwoDigits';
import updateRobinhoodToken from '../bin/updateRobinhoodToken';
import config from '../config.json';
import LOADING from "./LOADING";
import { XMarkIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useNavigate, useSearchParams } from 'react-router-dom';

const MAIN = (props) => {
	let data = props.data || null,
		token = props.token || null,
		[loading, setLoading] = useState(false),
		[balance, setBalance] = useState(null),
		[pendingOrder, setPendingOrder] = useState([]),
		[position, setPosition] = useState(null),
		[insufficentBalance, setInsufficentBalance] = useState(null),
		[contracts, setContracts] = useState(0),
		[totalCost, setTotalCost] = useState(0),
		[targetEntry, setTargetEntry] = useState(0),
		[targetExit, setTargetExit] = useState(0),
		[targetStop, setTargetStop] = useState(0),
		[submitting, setSubmitting] = useState(false),
		[expirationDatesContracts, setExpirationDatesContracts] = useState([]),
		[callContracts, setCallContracts] = useState([]),
		[putContracts, setPutontracts] = useState([]),
		[selectedOptionExpirationDate, setSelectedOptionExpirationDate] = useState(null),
		_selectedOptionExpirationDate = useRef(null),
		[selectedOptionType, setSelectedOptionType] = useState(null),
		[selectedOptionStrike, setSelectedOptionStrike] = useState(null),
		sendingOrder = useRef(false);

	const start = async () => {
		setLoading(true);
		let [_balance, _pendingOrders, _position, _contracts] = await Promise.all([
			fetch(`${config.api}/robinhoodbalance`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.cashBalance ? d.cashBalance : null),
			// fetch(`${config.api}/robinhoodbalance`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.buyingPower ? d.buyingPower : null),
			fetch(`${config.api}/robinhoodoptionorders?underlyingTicker=${data.UnderlyingTicker}&optionExpiration=${data.ExpirationDateString}&optionType=${data.OptionType}&optionStrike=${data.OptionStrike}`, { headers: Headers(token), }).then(d => d.json()).then(d => d && d.pendingOrders && d.pendingOrders.length && d.pendingOrders.length === 1 ? d.pendingOrders[0] : null),
			fetch(`${config.api}/robinhoodpositions?underlyingTicker=${data.UnderlyingTicker}&optionExpiration=${data.ExpirationDateString}&optionType=${data.OptionType}&optionStrike=${data.OptionStrike}`, { headers: Headers(token), }).then(d => d.json()).then(d => d && d.optionPositions && d.optionPositions.length && d.optionPositions.length === 1 ? d.optionPositions[0] : null),
			// fetch(`${config.api}/openinterest?ticker=${data.UnderlyingTicker}&date=${data.EntryDateString}`, { headers: Headers(token), }).then(d => d.json()).then(d => d && d.message==='ok' && d.result && d.result[0] && d.result[0].Snapshot ? d.result[0].Snapshot : null)
			fetch(`https://api.redstripedtie.com/openinterest?ticker=${data.UnderlyingTicker}`, { headers: Headers(token), }).then(d => d.json()).then(d => d && d.message==='ok' && d.result && d.result[0] && d.result[0].Snapshot ? d.result[0].Snapshot : null)
			// fetch(`https://io.redstripedtie.com/ursa/openinterest?ticker=${data.UnderlyingTicker}`, { headers: Headers(token), }).then(d => d.json()).then(d => d && d.message==='ok' && d.result && d.result[0] && d.result[0].Snapshot ? d.result[0].Snapshot : null)
		]);
		setBalance(_balance);
		setPendingOrder(_pendingOrders);
		setPosition(_position);
		calculateCosts({ balance: _balance }, true);
		setSelectedOptionType(data.OptionType);
		setSelectedOptionStrike(data.OptionStrike);
		_selectedOptionExpirationDate.current = data.ExpirationDateString;


		let _expirationDatesContracts = _contracts && _contracts.length ? _contracts.map(c => c.ExpirationDate).reduce((d, current) => { if (d[current]) { return d; } d[current] = true; return d; }, {}) : {};
		_expirationDatesContracts = _expirationDatesContracts && Object.keys(_expirationDatesContracts).length ? Object.keys(_expirationDatesContracts).sort((a,b)=> (new Date(a)).getTime() - (new Date(b)).getTime() ) : [];
		if (_expirationDatesContracts && _expirationDatesContracts.length) {
			setExpirationDatesContracts(_expirationDatesContracts);
			setSelectedOptionExpirationDate(_selectedOptionExpirationDate.current);
		}

		_contracts = _contracts && _contracts.length ? _contracts.filter(c => c.ExpirationDate===_selectedOptionExpirationDate.current) : [];
		let callContracts = _contracts.filter(c => c.Type==='CALL' && c.StrikePrice>=data.UnderlyingPriceAtTrade).sort((a, b) => a.StrikePrice - b.StrikePrice);
		let putContracts = _contracts.filter(c => c.Type === 'PUT' && c.StrikePrice<=data.UnderlyingPriceAtTrade).sort((a, b) => b.StrikePrice - a.StrikePrice);
		setCallContracts(callContracts);
		setPutontracts(putContracts);

		//inverse
		if ((data.TradeSide === 'SELL' && (data.OptionType === 'PUT' || data.Type === 'PUT')) || (data.TradeSide === 'BUY' && (data.OptionType === 'CALL' || data.Type === 'CALL'))) {
			setSelectedOptionType('PUT');
			setSelectedOptionStrike(putContracts[0].StrikePrice);
		} else if ((data.TradeSide === 'SELL' && (data.OptionType === 'CALL' || data.Type === 'CALL')) || (data.TradeSide === 'BUY' && (data.OptionType === 'PUT' || data.Type === 'PUT'))) {
			setSelectedOptionType('CALL');
			setSelectedOptionStrike(callContracts[0].StrikePrice);
		}

		setLoading(false);
	};

	const calculateCosts = (payload, fillContracts=false) => {
		let _balance = payload && payload.balance ? payload.balance : balance;
		let _entry = payload && payload.entry ? payload.entry : data.Price;
		let _contracts = payload && payload.contracts ? payload.contracts : contracts;
		if (_balance && _balance > 0) {
			if (_balance > (_entry * 100)) {
				if (fillContracts) {
					_contracts = Math.floor(_balance / ((_entry * 100)+0.03));
				}
				let _totalCost = Math.trunc(((_entry * 100)+0.03) * _contracts);
				let _targetExit = truncateToTwoDigits(_entry * 1.05);
				let _targetStop = truncateToTwoDigits(_entry * .95);
				setContracts(_contracts);
				setTargetEntry(_entry);
				setTotalCost(_totalCost);
				setTargetExit(_targetExit);
				setTargetStop(_targetStop);
			}
			// else { setInsufficentBalance(true); }
		} else {
			setContracts(0);
		}
	};

	// const updateRobinhoodToken = async (robinhoodToken) => {
	// 	let payload = { robinhoodToken };
	// 	let update = await fetch(`${config.api}/robinhoodtoken`, { method: "PUT", headers: Headers(token), body: JSON.stringify(payload) }).then(d => d.json());
	// 	if (update === true) { start(); }
	// };

	const cancelPendingOrder = async (robinhoodCancelUrl) => {
		let payload = { cancelUrl: robinhoodCancelUrl };
		let cancelled = await fetch(`${config.api}/robinhoodorder`, { method: "DELETE", headers: Headers(token), body: JSON.stringify(payload) }).then(d => d.json());
		start();
	};

	// const sendExitOrder = async () => {
	// 	if (sendingOrder.current) { return; }
	// 	sendingOrder.current = true;
	// 	setSubmitting(true);
	// 	let payload = {
	// 		trade: {
	// 			underlyingTicker: data.UnderlyingTicker,
	// 			optionExpiration: data.ExpirationDateString,
	// 			optionType: data.OptionType,
	// 			optionStrike: data.OptionStrike,
	// 			quantity: position.quantity,
	// 			price: targetExit,
	// 		}
	// 	};
	// 	let submission = await fetch(`${config.api}/robinhoodorderexit`, { method: "POST", headers: Headers(token), body: JSON.stringify(payload) }).then(d => d.json());
	// 	if (submission && submission.error) { alert(submission.error); }else{ start(); }
	// 	sendingOrder.current = false;
	// 	setSubmitting(false);
	// };
	
	const sendImmeditateExitOrder = async () => {
		// if (sendingOrder.current) { return; }
		// sendingOrder.current = true;
		// setSubmitting(true);
		let payload = {
			trade: {
				ticker: data.Ticker,
				underlyingTicker: data.UnderlyingTicker,
				optionExpiration: data.ExpirationDateString,
				optionType: data.OptionType,
				optionStrike: data.OptionStrike,
			}
		};
		console.log(payload);
		return;
	};

	// const sendStopOrder = async () => {
	// 	if (sendingOrder.current) { return; }
	// 	sendingOrder.current = true;
	// 	setSubmitting(true);
	// 	let payload = {
	// 		trade: {
	// 			underlyingTicker: data.UnderlyingTicker,
	// 			optionExpiration: data.ExpirationDateString,
	// 			optionType: data.OptionType,
	// 			optionStrike: data.OptionStrike,
	// 			quantity: position.quantity,
	// 			price: targetStop,
	// 		}
	// 	};
	// 	let submission = await fetch(`${config.api}/robinhoodorderexit`, { method: "POST", headers: Headers(token), body: JSON.stringify(payload) }).then(d => d.json());
	// 	if (submission && submission.error) { alert(submission.error); }else{ start(); }
	// 	sendingOrder.current = false;
	// 	setSubmitting(false);
	// };

	const sendEntryOrder = async () => {
		if (sendingOrder.current) { return; }
		sendingOrder.current = true;
		setSubmitting(true);
		let payload = {
			trade: {
				underlyingTicker: data.UnderlyingTicker,
				optionExpiration: selectedOptionExpirationDate,
				// optionExpiration: data.ExpirationDateString,
				// optionType: data.OptionType,
				// optionStrike: data.OptionStrike,
				optionType: selectedOptionType,
				optionStrike: selectedOptionStrike,
				// attemptOnce: true,
				attemptOnce: false,
				// quantity: contracts,
				// entry: targetEntry,
				// exit: targetExit,
				// stop: targetStop,
			}
		};
		// console.log(payload);
		// return;
		// let submission = await fetch(`${config.api}/robinhoodorder`, { method: "POST", headers: Headers(token), body: JSON.stringify(payload) }).then(d => d.json());
		let submission = await fetch(`${config.api}/robinhoodorderentryimmediate`, { method: "POST", headers: Headers(token), body: JSON.stringify(payload) }).then(d => d.json());
		if (submission && submission.error) { alert(submission.error); }else{ start(); }
		sendingOrder.current = false;
		setSubmitting(false);
	};

	const handleFocus = (event) => {
	// 	if (!copied) {
			event.target.select();
	// 		document.execCommand('copy');
	// 		alert('Text copied to clipboard!');
	// 		setCopied(true);
	// 	}
	};

	useEffect(() => { start(); }, [data]);

	return (
		<div>
			{loading === true && <div className='w-full'><LOADING color="text-green-700" /></div>}
			{loading === false ?
				<div className="mt-2 w-full flex flex-col items-start justify-center">
					<span className='w-full flex flex-col items-start justify-center'>
						<span className="flex flex-row w-full items-center justify-center font-light gap-1 text-sky-500">
							{data.TradeSide ? <span className={`${data.TradeSidePrecise ? 'border border-indigo-500' : ''} text-sm font-light text-center px-2 py-0 rounded inline-flex items-center justify-center ${data.TradeSide === 'BUY' ? 'text-emerald-700 bg-emerald-300' : ''}${data.TradeSide === 'SELL' ? 'text-rose-700 bg-rose-300' : ''}`}>{data.TradeSide ? data.TradeSide : ''}</span> : ``}
							<span className='font-light w-100 text-center'>{data.UnderlyingTicker ? data.UnderlyingTicker : ''}</span>
							{data.Type || data.OptionType ? <span className={`text-sm font-light text-center px-2 py-0 rounded inline-flex items-center justify-center ${(data.OptionType === 'CALL' || data.Type === 'CALL') ? 'text-emerald-700 bg-emerald-300' : ''}${(data.OptionType === 'PUT' || data.Type === 'PUT') ? 'text-rose-700 bg-rose-300' : ''}`}>{data.OptionType ? data.OptionType : (data.Type ? data.Type : '')}</span> : ``}
							{data.ExpirationDateString ? <span className="text-sm font-light text-center px-2 py-0 rounded inline-flex items-center justify-center text-violet-700 bg-violet-300">{data.ExpirationDateString}</span> : ``}
							<span className='font-light w-100 text-center'>${data.OptionStrike ? (data.OptionStrike).toLocaleString() : (data.Strike ? (data.Strike).toLocaleString() : '')}</span>
						</span>
						<span className="flex flex-row w-full items-center justify-center font-light gap-1 text-sky-500">
							<span className='font-light w-100 text-center'>{data.Size ? (data.Size).toLocaleString() : ''}</span>
							<span className='font-light w-100 text-center'>@</span>
							<span className='font-light w-100 text-center'>${data.Price ? (data.Price).toLocaleString() : ''} <span className='text-sm font-light text-center text-amber-700 py-0 px-2 rounded bg-amber-300'>${data.Notional ? (data.Notional).toLocaleString() : ''}</span></span>
						</span>
						{/* <span className="flex flex-row w-full items-center justify-center">
							<input value={data._id} onFocus={handleFocus} readOnly className="border border-indigo-800 bg-indigo-950 text-xxs px-2 rounded font-light gap-1 text-sky-500" />
						</span> */}
					</span>

					{!loading && !balance ? <span className="flex flex-row w-full items-center justify-center">
						<input placeholder='robinhood token' defaultValue='' onKeyDown={async (evt) => { if (evt.key === 'Enter') { let update = await updateRobinhoodToken(evt.target.value, token); if (update === true) { start(); } } }} className="border border-indigo-800 text-xxs px-2 rounded font-light gap-1 text-sky-500" />
					</span>
					: <></>}
					
					{!loading && position ? <span className="flex flex-col w-full items-center justify-center">
						<hr className='bg-black w-full my-2'/>
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-100 text-left'>Position:</span>
							<span className="font-light w-100 inline-flex items-center gap-0.5 justify-end">
								<span className="font-light text-center text-gray-700 px-1 py-1 bg-gray-300 rounded inline-flex items-center justify-center text-xs">
									{(Number(position.quantity)).toLocaleString()} for ${ (Number(position.average_open_price)).toLocaleString() }
								</span>
								<span className="ml-1 font-light text-center text-rose-700 px-1 py-1 bg-rose-300 rounded inline-flex items-center justify-center text-xs">-${ (Number(position.average_open_price * position.quantity)).toLocaleString() }</span>
								{submitting ? <span className='inline-flex text-xs'><LOADING color="text-green-700" text={false} /></span> : <></>}
								{!submitting ? <button onClick={() => { sendImmeditateExitOrder() }} className="py-1 px-1 text-sm border border-black shadow font-light text-center rounded inline-flex items-center justify-center text-cyan-900 bg-cyan-300"><XMarkIcon className="h-4 w-4" aria-hidden="true" /></button> : <></>}
							</span>
						</span>
					</span>
					: <></>}
					
					{!loading && pendingOrder ? <span className="flex flex-col w-full items-center justify-center">
						<hr className='bg-black w-full my-2'/>
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-100 text-left'>Pending:</span>
							<span className="ml-1 font-light w-100 text-right inline-flex items-center">
								<span className="font-light text-center text-gray-700 px-2 py-0 bg-gray-300 rounded inline-flex items-center justify-center text-xs mr-1">
									<span>{pendingOrder.legs && pendingOrder.legs[0] && pendingOrder.legs[0].option_type ? (pendingOrder.legs[0].option_type).toUpperCase() : ''}</span>
									<span>{ pendingOrder.legs && pendingOrder.legs[0] && pendingOrder.legs[0].strike_price ? `$${Number(pendingOrder.legs[0].strike_price)}` : '' }</span>
								</span>
								<span className="font-light text-center text-gray-700 px-2 py-0 bg-gray-300 rounded inline-flex items-center justify-center text-xs">{ (Number(pendingOrder.pending_quantity)).toLocaleString() }</span>
								<span className="font-light text-center text-gray-700 px-2 py-0 rounded inline-flex items-center justify-center text-xs">for</span>
								<span className="font-light text-center text-gray-700 px-2 py-0 bg-gray-300 rounded inline-flex items-center justify-center text-xs">${ (Number(pendingOrder.price)).toLocaleString() }</span>
								<span className="ml-1 font-light text-center text-green-700 px-2 py-0 bg-green-300 rounded inline-flex items-center justify-center text-xs">{pendingOrder.state}</span>
								<button onClick={() => { cancelPendingOrder(pendingOrder.cancel_url) }} className="p-0 ml-2 text-sm border border-black shadow font-light text-center rounded inline-flex items-center justify-center text-rose-900 bg-rose-300">
									<XMarkIcon className="h-4 w-4" aria-hidden="true" />
								</button>
							</span>
						</span>
					</span>
					: <></>}
					
					{!loading && balance ? <span className="flex flex-col w-full items-center justify-center">
						<hr className='bg-black w-full my-2'/>
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className='font-light text-gray-400 w-100 text-left'>Balance:</span>
							<span className="ml-1 font-light w-100 text-right text-gray-800">{`$${balance.toLocaleString()}`}</span>
						</span>
						{insufficentBalance ?
							<span className="flex flex-row w-full items-start justify-between gap-1 text-red-500">
								Insufficent Balance
							</span>
							:
							<span className="flex flex-col w-full items-center justify-center">
								
								{/* {!position ? <span className="flex flex-row w-full items-start justify-between gap-1"> */}
								<span className="flex flex-row w-full items-start justify-between gap-1">
									<span className='font-light text-gray-400 w-100 text-left'>Entry:</span>
									<span className="ml-1 font-light inline-flex flex-col sm:flex-row items-end sm:items-center justify-end gap-0.5">

										<select onChange={(evt) => { setSelectedOptionExpirationDate(evt.target.value); }} className="border border-indigo-800 rounded font-light text-center text-amber-500" defaultValue={selectedOptionExpirationDate}>
											{expirationDatesContracts && expirationDatesContracts.length ? expirationDatesContracts.map((c, i) => <option key={i} value={c}>{c}</option>) : <></>}
										</select>
										<span className='inline-flex flex-row items-center justify-end gap-0.5'>
											<select onChange={(evt) => { setSelectedOptionType(evt.target.value); if (evt.target.value === 'CALL') { setSelectedOptionStrike(callContracts[0].StrikePrice); } else if (evt.target.value === 'PUT') { setSelectedOptionStrike(putContracts[0].StrikePrice); } }} className="border border-indigo-800 rounded font-light text-center text-amber-500" defaultValue={selectedOptionType}>
												<option value=''>-</option>
												<option value='CALL'>CALL</option>
												<option value='PUT'>PUT</option>
											</select>
											<select onChange={(evt) => { setSelectedOptionStrike(evt.target.value); }} className="border border-indigo-800 rounded font-light text-center text-amber-500" defaultValue={selectedOptionStrike}>
												<option value=''>-</option>
												{selectedOptionType === 'CALL' ? callContracts.map((c, i) => <option key={i} value={c.StrikePrice}>{c.StrikePrice}</option>) : <></>}
												{selectedOptionType === 'PUT' ? putContracts.map((c, i) => <option key={i} value={c.StrikePrice}>{c.StrikePrice}</option>) : <></>}
											</select>
											{/* <input placeholder='contracts' defaultValue={contracts} onChange={(evt) => { setContracts(evt.target.value); calculateCosts({ contracts: evt.target.value }); }} className="border border-indigo-800 rounded font-light w-8 text-center text-sky-500" /> */}
											{/* <span className="font-light text-center text-gray-700 px-2 py-1 bg-gray-300 rounded inline-flex items-center justify-center text-xs">for</span> */}
											{/* <input placeholder='entry' onFocus={handleFocus} defaultValue={targetEntry} onChange={(evt) => { setTargetEntry(evt.target.value); calculateCosts({ entry: evt.target.value }); }} className="border border-indigo-800 rounded font-light w-14 text-red-500 text-center" /> */}
											{/* <span className="font-light text-center text-amber-700 px-2 py-1 bg-amber-300 rounded inline-flex items-center justify-center text-xs">
												${(totalCost).toLocaleString()}
											</span> */}
											{submitting ? <span className='inline-flex text-xs'><LOADING color="text-green-700" text={false} /></span> : <></>}
											{!submitting ? <button onClick={() => { sendEntryOrder() }} className="py-3 px-3 text-sm border border-black shadow font-light text-center rounded block text-amber-900 bg-amber-300"><PaperAirplaneIcon className="h-4 w-4" aria-hidden="true" /></button> : <></>}
										</span>
									</span>
								</span>
								
								{/* {position ? <span className="mt-3 flex flex-row w-full items-start justify-between gap-1">
									<span className='font-light text-gray-400 w-100 text-left'>Exit:</span>
									<span className="ml-1 font-light w-100 inline-flex items-center justify-end gap-0.5">
										<span className="font-light text-center text-gray-700 px-2 py-1 bg-gray-300 rounded inline-flex items-center justify-center text-xs">+5%</span>
										<input placeholder='exit' onFocus={handleFocus} defaultValue={targetExit} onChange={(evt) => { setTargetExit(evt.target.value); }} className="border border-indigo-800 rounded font-light w-14 text-green-500 text-center" />
										<span className="font-light text-center text-green-700 px-2 py-1 bg-green-300 rounded inline-flex items-center justify-center text-xs">
											${(targetExit * 100 * position.quantity).toLocaleString()}
										</span>
										{submitting ? <span className='inline-flex text-xs'><LOADING color="text-green-700" text={false} /></span> : <></>}
										{!submitting ? <button onClick={() => { sendExitOrder() }} className="py-1 px-1 text-sm border border-black shadow font-light text-center rounded inline-flex items-center justify-center text-emerald-900 bg-emerald-300"><PaperAirplaneIcon className="h-4 w-4" aria-hidden="true" /></button> : <></>}
									</span>
								</span> : <></>} */}
								
								{/* {position ? <span className="mt-3 flex flex-row w-full items-start justify-between gap-1">
									<span className='font-light text-gray-400 w-100 text-left'>Stop:</span>
									<span className="ml-1 font-light w-100 inline-flex items-center justify-end gap-0.5">
										<span className="font-light text-center text-gray-700 px-2 py-1 bg-gray-300 rounded inline-flex items-center justify-center text-xs">-5%</span>
										<input placeholder='stop' onFocus={handleFocus} defaultValue={targetStop} onChange={(evt) => { setTargetStop(evt.target.value); }} className="border border-indigo-800 rounded font-light w-14 text-red-500 text-center" />
										<span className="font-light text-center text-red-700 px-2 py-1 bg-red-300 rounded inline-flex items-center justify-center text-xs">
											${(targetStop * 100 * position.quantity).toLocaleString()}
										</span>
										{submitting ? <span className='inline-flex text-xs'><LOADING color="text-green-700" text={false} /></span> : <></>}
										{!submitting ? <button onClick={() => { sendStopOrder() }} className="py-1 px-1 text-sm border border-black shadow font-light text-center rounded inline-flex items-center justify-center text-rose-900 bg-rose-300"><PaperAirplaneIcon className="h-4 w-4" aria-hidden="true" /></button> : <></>}
									</span>
								</span> : <></>} */}

							</span>
						}
					</span>
					: <></>}

					{/* 

					{shareLoadingValue === false ?
						<span className="mt-5 flex flex-row w-full items-center justify-center">
							<input type="text" value={tweetId} onKeyDown={(evt) => { if (evt.key === 'Enter') { extractTweetId(); } }} onBlur={(evt) => { extractTweetId(); }} onChange={(evt) => setTweetId(evt.target.value)} className="border border-gray-300 rounded px-4 py-2 w-64 focus:border-blue-500 focus:outline-none" placeholder="TweetId" />
						</span>
						: <></>}
					{shareLoadingValue === false && !urlTweetType ?
						<span className="mt-5 flex flex-row gap-2 w-full items-center justify-center">
							<button onClick={() => { getShareImageDownload('initial') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-cyan-900 bg-cyan-300">Initial Tweet</button>
							{tweetId ? <button onClick={() => { getShareImageDownload('confirm') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-amber-900 bg-amber-300">Confirm Tweet</button> : <></>}
							{tweetId ? <button onClick={() => { getShareImageDownload('result') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-indigo-900 bg-indigo-300">Result Tweet</button> : <></>}
						</span>
						: <></>}
					{shareLoadingValue === false && urlTweetType === 'confirm' && tweetId ?
						<span className="mt-5 flex flex-row gap-2 w-full items-center justify-center">
							<button onClick={() => { getShareImageDownload('confirm') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-amber-900 bg-amber-300">Confirm Tweet</button>
						</span>
						: <></>}
					{shareLoadingValue === false && urlTweetType === 'result' && tweetId ?
						<span className="mt-5 flex flex-row gap-2 w-full items-center justify-center">
							<button onClick={() => { getShareImageDownload('result') }} className="text-sm border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-indigo-900 bg-indigo-300">Result Tweet</button>
						</span>
						: <></>}
					{shareImage ? <span className="mt-10 flex flex-row w-full items-center justify-center">
						<img src={shareImage} alt="Screenshot" />
					</span> : <></>}
					{shareTweetLink ? <span className="mt-10 flex flex-row w-full items-center justify-center">
						<a href={shareTweetLink} target='_blank' className="text-xs border border-black shadow font-light text-center px-4 py-2 rounded inline-flex items-center justify-center text-emerald-900 bg-emerald-300">{shareTweetLink}</a>
					</span> : <></>} */}

				</div>
				: <></>
			}
		</div>
	);
};

export default React.memo(MAIN, dontRerender);