import React, { useEffect, useRef, useState } from 'react';
import dontRerender from "../bin/dontRerender";
import * as echarts from 'echarts';
import truncateToTwoDigits from '../bin/truncateToTwoDigits';
import abbrInt from '../bin/abbrInt';

const MAIN = ({ timing, candles, marker, loading, last, orderBook, showOrderBook, tickerStats, showPriceLevels}) => {
	const chartRef = useRef(null);
	const chartContainerRef = useRef(null);
	const [height, setHeight] = useState(300); // Default height
	const _bidSideOrders = useRef([]),
				_askSideOrders = useRef([]);

	useEffect(() => {
    // Update height on load and resize
    const updateHeight = () => {
			if (chartContainerRef.current) {
        const containerHeight = chartContainerRef.current.offsetHeight;
        setHeight(containerHeight);
      }
    };

    // Add a resize observer to dynamically update the height
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    if (chartContainerRef.current) {
      resizeObserver.observe(chartContainerRef.current);
    }

    // Cleanup on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

	useEffect(() => {
    if (!candles) { return; }

		const chart = echarts.init(chartRef.current);

		if (loading) {
			chart.showLoading({
				text: 'Loading...', // Loading text
				color: '#fff',
				textColor: '#aaa',
				maskColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
				zlevel: 0,
			});
		} else {
			chart.hideLoading();
		}

		const formatTimestring = (time) => {
			if (!time || time.indexOf(':') === -1) { return time; }
			time = time.split(':');
			return `${time[0]}:${time[1]}`;
		};

    const candleData = candles.map(candle => ({
      value: [candle.Open, candle.Close, candle.Low, candle.High, candle.Volume],
			time: timing && timing==='minute' ? formatTimestring(candle.TimeString) : candle.DateString,
		}));
		const lastCandle = candles[candles.length - 1];
		const lastClose = last ? last : (lastCandle ? lastCandle.Close : null);

		const maxVolume = Math.max(...candles.map(candle => candle.Volume));

    const volumeData = candles.map(candle => ({
      value: candle.Volume * 0.30,
      date: candle.DateString,
      itemStyle: {
        color: candle.Close >= candle.Open ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)',
      },
    }));

		const highPrices = [...candleData.map(item => item.value[3])];
		const lowPrices = [...candleData.map(item => item.value[2])];
		if (marker && marker.Price && !isNaN(marker.Price)) {
			highPrices.push(marker.Price);
			lowPrices.push(marker.Price);
		}

    let yAxisMin = Math.min(...lowPrices);
		let yAxisMax = Math.max(...highPrices);

		//add some "padding" to the chart by adding some extra price spacing to the y axis
		let _yAxisMin = yAxisMin && !isNaN(yAxisMin) ? truncateToTwoDigits(yAxisMin * 0.999) : yAxisMin;
		let _yAxisMax = yAxisMax && !isNaN(yAxisMax) ? truncateToTwoDigits(yAxisMax * 1.001) : yAxisMax;
		if (_yAxisMin && !isNaN(_yAxisMin) && _yAxisMin > 0) { yAxisMin = _yAxisMin; }
		if (_yAxisMax && !isNaN(_yAxisMax) && _yAxisMax > 0) { yAxisMax = _yAxisMax; }

		//handle orderBook displaying
		let orderBookMarks = [];
		if (orderBook && showOrderBook) {
			let __bidSideOrders = !orderBook?.BidSideOrders?.length ? [] : [...orderBook.BidSideOrders];
			let __askSideOrders = !orderBook?.AskSideOrders?.length ? [] : [...orderBook.AskSideOrders];
			_bidSideOrders.current = aggregateOrdersByPrice(__bidSideOrders, 'descending');
			_askSideOrders.current = aggregateOrdersByPrice(__askSideOrders, 'ascending');
			if (yAxisMax) { _askSideOrders.current = (_askSideOrders.current).filter(pricelevel => pricelevel[0] <= yAxisMax); }    //since yAxisMax is the highest price level, filter the _askSideOrders to under
			if (yAxisMin) { _bidSideOrders.current = (_bidSideOrders.current).filter(pricelevel => pricelevel[0] >= yAxisMin); }    //since yAxisMin is the lowest price level, filter the _bidSideOrders to over

			const maxSize = Math.max(..._bidSideOrders.current.map(order => order[1]), ..._askSideOrders.current.map(order => order[1]), -Infinity);
			const minSize = Math.min(..._bidSideOrders.current.map(order => order[1]), ..._askSideOrders.current.map(order => order[1]), Infinity);

			for (let order of _bidSideOrders.current) {
				let sized = scaleOrderSize(order[1], minSize, maxSize);
				orderBookMarks.push({ coord: [0, order[0]], x: 0, symbolSize: [sized, 1], itemStyle: { color: 'rgba(0, 153, 255, 0.6)' } });
			}
			for (let order of _askSideOrders.current) {
				let sized = scaleOrderSize(order[1], minSize, maxSize);
				orderBookMarks.push({ coord: [0, order[0]], x: 0, symbolSize: [sized, 1], itemStyle: { color: 'rgba(255, 204, 0, 0.6)' } });
			}

			function scaleOrderSize(size, minSize, maxSize) {
				const minScaled = 10;                                                                   // Minimum width
				const maxScaled = 200;                                                                  // Maximum width
				if (minSize === maxSize) return minScaled;                                              // Prevent division by zero
				return ((size - minSize) / (maxSize - minSize)) * (maxScaled - minScaled) + minScaled;  // Linear interpolation (scaling formula)
			}		
		}

		//handle priceLevel displaying
		let priceLevels = [];
		if (tickerStats && tickerStats.PriceLevels && showPriceLevels) {
			let _priceLevels = tickerStats.PriceLevels.filter(x => x.price && x.price >= yAxisMin && x.price <= yAxisMax);
			priceLevels = _priceLevels.map(entry => {
				let p = {
					name: `${entry.price}`,
					yAxis: entry.price,
					label: {
						show: true,
						position: entry.price <= lastClose ? 'insideStartTop' : 'insideStartBottom',
						formatter: `$${truncateToTwoDigits(entry.price)}`, // Format the label text
						color: 'rgba(255, 255, 255, 0.75)',
						fontSize: '10',
						fontWeight: 'lighter',
						fontFamily: 'monospace',
					},
					lineStyle: {
						type: 'dotted',
						color: entry.dates && entry.dates.length === 1 ? 'rgb(255, 191, 0)' : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							// { offset: 0, color: 'rgb(4, 119, 148)' },
							// { offset: 1, color: 'rgb(4, 119, 148)' }
							{ offset: 0, color: 'rgba(255, 255, 255, 0.75)' },
							{ offset: 1, color: 'rgba(255, 255, 255, 0.75)' }
						])
					}
				};
				return p;
			});
		}

		//define the first timestamp and the last timestamp for pre and post shading
		let firstPreMarketTimestamp = null;
		let lastPreMarketTimestamp = null;
		let firstPostMarketTimestamp = null;
		let lastPostMarketTimestamp = null;
		for (let candle of candles) {
			//the first PRE candle should be our start
			if (firstPreMarketTimestamp === null && candle.Market === 'PRE') {
				firstPreMarketTimestamp = formatTimestring(candle.TimeString);
			}
			//the first OPEN candle should be the end of the PRE market section
			if (lastPreMarketTimestamp===null && candle.Market === 'OPEN') {
				lastPreMarketTimestamp = formatTimestring(candle.TimeString);
			}
			//the first POST candle should be our start
			if (firstPostMarketTimestamp === null && candle.Market === 'POST') {
				firstPostMarketTimestamp = formatTimestring(candle.TimeString);
			}
			//always declare the latest POST candle as the last until the end
			if (candle.Market === 'POST') {
				lastPostMarketTimestamp = formatTimestring(candle.TimeString);
			}
		}
		if (!firstPreMarketTimestamp) { firstPreMarketTimestamp = '04:00'; }
		if (!lastPreMarketTimestamp) { lastPreMarketTimestamp = '09:30'; }
		if (!firstPostMarketTimestamp) { firstPostMarketTimestamp = '16:00'; }
		if (!lastPostMarketTimestamp) { lastPostMarketTimestamp = '20:00'; }

		//if we're currently in the PRE market, make sure to shade properly
		if (lastCandle && lastCandle.Market === 'PRE') { lastPreMarketTimestamp = formatTimestring(lastCandle.TimeString); }

    const option = {
			textStyle: {
				color: 'white',
			},
			grid: {
				left: '0',
				right: '0',
				top: '0',
				bottom: '0',
				height: '100%',
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
				},
				backgroundColor: 'rgba(0, 0, 0, 0.80)',
				position: function (point, params, dom, rect, size) {
					const chartWidth = size.viewSize[0];
					const tooltipWidth = size.contentSize[0];
					const x = point[0];
		
					if (x < chartWidth / 2) {
						return [chartWidth - tooltipWidth, 0];
					} else {
						return [0, 0];
					}
				},
				formatter: function (params) {
					const time = params[0].axisValue;
					const priceData = params.find(param => param.seriesName === 'Price')?.data;
					const open = priceData && !isNaN(priceData[1]) ? priceData[1].toLocaleString() : '-';
					const high = priceData && !isNaN(priceData[4]) ? priceData[4].toLocaleString() : '-';
					const low = priceData && !isNaN(priceData[3]) ? priceData[3].toLocaleString() : '-';
					const close = priceData && !isNaN(priceData[2]) ? priceData[2].toLocaleString() : '-';
					const volume = priceData && !isNaN(priceData[5]) ? priceData[5].toLocaleString() : '-';
		
					return `
						<div style="padding: 0px; text-align: left; font-size:12px;">
							<span style="color:white;">● Time: ${time}</span><br/>
							<span style="color:white;">● Open: ${open}</span><br/>
							<span style="color:white;">● High: ${high}</span><br/>
							<span style="color:white;">● Low: ${low}</span><br/>
							<span style="color:white;">● Close: ${close}</span><br/>
							<span style="color:white;">● Volume: ${volume}</span><br/>
						</div>
					`;
				},
			},
			xAxis: [
				{
					type: 'category',
					data: candleData.map(item => item.time),
					boundaryGap: true,
					axisLine: {
						onZero: false,
						lineStyle: {
							color: 'black',
						},
					},
					splitLine: { show: false },
					min: 'dataMin',
					max: 'dataMax',
					show: false,
				},
			],
			yAxis: [
				{
					show: false,
					type: 'value',
					position: 'right',
					min: yAxisMin,
					max: yAxisMax,
					axisLabel: {
						color: 'white',
						fontSize: (() => {
							const width = window.innerWidth;
							if (width >= 1024) return 14; // Large screens
							if (width >= 768) return 12; // Medium screens
							return 10; // Small screens
						})(),
						formatter: (value) => `$${truncateToTwoDigits(value)}`,
					},
					axisLine: {
						onZero: false,
						lineStyle: {
							color: 'black',
						},
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.2)',
						},
					},
					interval: 5,
					axisPointer: {
						label: {
							formatter: (value) => `$${abbrInt(value.value)}`,
						},
					},
				},
				{
					type: 'value',
					name: 'Volume',
					show: false,
					min: 0,
					max: maxVolume,
					axisLabel: {
						show: false,
					},
					axisLine: {
						show: false,
					},
					splitLine: { show: false },
					axisPointer: {
						show: false,
					},
				},
				{
					type: 'value',
					axisLine: {
						lineStyle: {
							color: 'white',
						},
					},
					splitLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.2)',
						},
					},
				},
			],
			dataZoom: [
				{
					type: 'inside',
					start: 0,
					end: 100,
				},
				{
					// show: true,
					show: false,
					type: 'slider',
					bottom: '0%',
					start: 0,
					end: 100,
				},
			],
			series: [
				{
					type: 'candlestick',
					name: 'Price',
					data: candleData.map(item => item.value),
					itemStyle: {
						color: 'green',
						color0: 'red',
						borderColor: 'green',
						borderColor0: 'red',
					},
					markLine: {
						symbol: 'none',
						silent: false, // Allow interaction for the marker
						lineStyle: {
							width: 1,
							type: 'dotted',
						},
						data: [
							// Horizontal line at the last candle's close
							...(lastClose && !isNaN(lastClose)
								?
								[
									{
										yAxis: lastClose,
										lineStyle: { color: 'rgba(255, 255, 255, 0.1)', },
										label: {
											color: 'rgba(255, 255, 255, 0.75)',
											fontSize: '10',
											fontWeight: 'lighter',
											fontFamily: 'monospace',
											show: true,
											position: 'insideEnd', // Position the label near the end of the line
											formatter: `$${truncateToTwoDigits(lastClose)}`, // Format the label text
										},
									}
								]
							: []),
							// Marker lines (vertical and horizontal)
							...(marker && !loading
								? [
										{
											xAxis: formatTimestring(marker.EntryTimeString), // Vertical line at the marker's time
											lineStyle: { color: marker.TradeSide === 'SELL' ? 'rgba(187, 3, 55, 1)' : (marker.TradeSide === 'BUY' ? 'rgba(18, 167, 4, 1)' : 'rgba(4, 118, 167, 1)'), },
										},
										{
											yAxis: marker.Price, // Horizontal line at the marker's price
											lineStyle: { color: marker.TradeSide === 'SELL' ? 'rgba(187, 3, 55, 1)' : (marker.TradeSide === 'BUY' ? 'rgba(18, 167, 4, 1)' : 'rgba(4, 118, 167, 1)'), },
										},
									]
								: []),
						],
					},

					//for orderBook
					markPoint: showOrderBook
					? {
						symbol: 'rect',
						silent: true,
						data: orderBookMarks,
						// symbolSize: [50, 1], //[length_relative_to_all_order_sizes, 1]
						// itemStyle: {
						// 	// color: 'rgba(255, 204, 0, 0.6)', //ask orders
						// 	color: 'rgba(0, 153, 255, 0.6)', //bid orders
						// },
						// data: [{
						// 	coord: [0, 606.00], //[0, price_of_order]
						// 	x: '98%',
						// }]
					} : null,


					markArea: {
						silent: true, // Prevent interaction with the area
						itemStyle: {
							color: 'rgba(0, 0, 0, 0.3)', // Gray with 50% opacity
						},
						data: [
							[
								{
									xAxis: firstPreMarketTimestamp, // Start time
								},
								{
									xAxis: lastPreMarketTimestamp, // End time
								},
							],
							[
								{
									xAxis: firstPostMarketTimestamp, // Start time
								},
								{
									xAxis: lastPostMarketTimestamp, // End time
								},
							],
						],
					},
				},
				{
					type: 'bar',
					name: 'Volume',
					xAxisIndex: 0,
					yAxisIndex: 1,
					data: volumeData.map(item => item.value),
					barWidth: '60%',
					itemStyle: {
						color: function (params) {
							return candles[params.dataIndex].Close >= candles[params.dataIndex].Open
								? 'rgba(0, 255, 0, 0.3)'
								: 'rgba(255, 0, 0, 0.3)';
						},
					},
				},
				{
					type: 'line',
					// silent: true,
					// label: { show: false },
					// axisLabel: { show: false, },
					// axisPointer: { show: false, },
					markLine: showPriceLevels ?
						{
							symbol: ['none', 'none'], // Remove the starting dot and ending arrow
							data: priceLevels
						} : null,
				}
			],
		};
		
		chart.setOption(option);

		// Resize handler
    const resizeObserver = new ResizeObserver(() => { chart.resize(); });
    resizeObserver.observe(chartRef.current);

		return () => {
			resizeObserver.disconnect();
      chart.dispose();
    };
	}, [candles, loading, last, orderBook, showOrderBook, tickerStats, showPriceLevels]);
	
	function aggregateOrdersByPrice(orders, sorting) {
		if (!orders?.length) { return orders; }
		let formattedOrders = {};
		for (let entry of orders) {
			let size = isNaN(entry[1]) ? 0 : Number(entry[1]);
			let price = isNaN(entry[2]) ? 0 : Number(entry[2]);
			let value = isNaN(entry[3]) ? 0 : entry[3];
			let inbounds = entry[5];
			if (!formattedOrders[price.toString()]) {
				formattedOrders[price.toString()] = [0, 0, 0, false];
			}
			formattedOrders[price.toString()] = [
				price,
				formattedOrders[price.toString()][1] + size,
				formattedOrders[price.toString()][2] + value,
				inbounds,
			];
		}
		if (sorting === 'descending') {
			formattedOrders = Object.values(formattedOrders).sort((a, b) => {
				if (a[0] > b[0]) { return -1; }
				if (a[0] < b[0]) { return 1; }
				return 0;
			});
		}
		if (sorting === 'ascending'){
			formattedOrders = Object.values(formattedOrders).sort((a, b) => {
				if (a[0] < b[0]) { return -1; }
				if (a[0] > b[0]) { return 1; }
				return 0;
			});
		}
		return formattedOrders;
	}

	return (
		// <div className='flex flex-col items-start justify-start w-full h-full'>
			<div ref={chartContainerRef} style={{ width: '100%', height: '100%' }}>
				<div ref={chartRef} style={{ width: '100%', height: `${height}px` }} />
			</div>
		// </div>
  );
};

// export default MAIN;
export default React.memo(MAIN, dontRerender);
