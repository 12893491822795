import React, { useEffect, useState, useRef } from 'react';
import LOADING from "../components/LOADING";
import hideKeyboardOnEnter from "../bin/hideKeyboardOnEnter";
import { Combobox } from '@headlessui/react';
import config from "../config.json";
import Headers from "../bin/Headers";
import { StarIcon } from '@heroicons/react/24/solid';
import { CheckIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

const TICKER_INPUT_WITH_INFO_BUTTON = (props) => {
  let ticker = props.ticker,
      loadTickerData = props.loadTickerData,
      token = props.token,
      showTickerInfo = props.showTickerInfo,
      updateShowTickerInfo = props.updateShowTickerInfo,
      [localTicker, updateLocalTicker]= useState(ticker),
      _localTicker = useRef(ticker),
      [suggestions, setSuggestions] = useState([]),
      [onWatchList, setOnWatchList] = useState(false),
      [onWatchListLoading, setOnWatchListLoading] = useState(false);

  const autoselectAllText = (obj) => {
    if (!obj || !obj.target){ return; }
    if (!obj.target.select){ return; }
    obj.target.select();
    if (!obj.target.setSelectionRange){ return; }
    obj.target.setSelectionRange(0, obj.target.value.length);
  };

  const capitalizeAndUpdateTicker = (input) => {
    input = input.toString().toUpperCase().trim();
    if (!input){ 
			updateLocalTicker("");
			_localTicker.current = '';
      setSuggestions([]);
      return input;
    }
    updateLocalTicker(input);
    suggestTicker(input);
    return input;
  };

  const suggestTicker = async (input) => {
    let payload={ headers: Headers(token) };
    let suggest = await fetch(`${ config.api }/ticker?q=${input}`, payload).then( d => d.json() );
    if (suggest.message==='ok' && suggest.result){
      setSuggestions(suggest.result);
    }
    return;
  };

  const selectSuggestion = (input) => {
    let _ticker = capitalizeAndUpdateTicker(input);
    // hideKeyboardOnEnter(event.target);
		// updateTicker(localTicker);
		if (_localTicker.current === _ticker) { return; }
		_localTicker.current = _ticker;
    loadTickerData(_ticker);
  };

  const checkForEnter = (event) => {
    if (!event || !event.keyCode || event.keyCode!==13 || ticker.trim()===''){ return; }
    // if (!event || !event.keyCode || event.keyCode!==13 || ticker.trim()===''){ return event; }
    hideKeyboardOnEnter(event.target);
    // updateTicker(localTicker);
    // let _ticker = event.target.value;
    // loadTickerData(_ticker);
    // return event;
  };

  const checkWatchList = async () => {
		setOnWatchListLoading(true);
    let _onWatchList = await fetch(`${ config.api }/onwatchlist?ticker=${ticker}`, { headers:Headers(token) }).then( d => d.json() ).then( d => d.message==='ok' ? d.result : null);
		setOnWatchListLoading(false);
		if (_onWatchList===true){
			setOnWatchList(true);
		}else{
			setOnWatchList(false);
		}
  };

	const addOrRemoveFromWatchList = async (_ticker) => {
		if (!token) {
			alert("login required");
			return;
		}
    if (onWatchList){
      setOnWatchListLoading(true);
      await fetch(`${ config.api }/watchlist?ticker=${_ticker}`, { headers:Headers(token), method:"DELETE" });
      await checkWatchList();
    }else{
    	setOnWatchListLoading(true);
      await fetch(`${ config.api }/watchlist?ticker=${_ticker}`, { headers:Headers(token), method:"PUT" });
      await checkWatchList();
    }
  };

	useEffect(() => {
		if (localTicker !== ticker) {
			updateLocalTicker(ticker);
		}
		if (token) { checkWatchList(); }
	}, [ticker]);

  return (
    // <div className="flex rounded-md w-full md:w-96">
    <div className="flex rounded-md w-full">
      <span className="inline-flex items-center px-1 rounded-l-md border border-r-md border-gray-500 bg-gradient-to-r from-blue-800 to-blue-600">
				{ onWatchListLoading ?
					<LOADING text="false" />
				:
					<button
						type="button"
						className="mx-auto flex-shrink-0 flex items-center justify-center"
						onClick={() => addOrRemoveFromWatchList(ticker)}>
						{ onWatchList
							? <StarIcon className="h-6 w-6 text-yellow-300" aria-hidden="true" />
							: <StarIcon className="h-6 w-6 text-white" aria-hidden="true" />
						}
					</button>
				}
      </span>

      <Combobox as="div" className="w-full" value={localTicker} onChange={selectSuggestion}>
        <div className="relative block m-0 h-full">
          <Combobox.Input
            tabIndex="1"
            className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none border-gray-500 bg-gray-800 uppercase text-center"
            onFocus={ autoselectAllText }
            onChange={(e) => capitalizeAndUpdateTicker(e.target.value)}
            onKeyDown={ checkForEnter }
            placeholder="Ticker"
            displayValue={localTicker} />
          {suggestions.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-0 max-h-60 w-full overflow-y-auto overflow-x-hidden rounded-md bg-white py-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {suggestions.map((suggestion) => (
                <Combobox.Option
                  key={suggestion._id}
                  value={suggestion.Ticker}
                  className={`relative cursor-default select-none m-0`}>

                  {({ active }) => (
                    <div className={`p-1 flex items-start flex-col ${ active || suggestion.Ticker===ticker ? 'bg-sky-500 text-white' : 'text-black'}`}>
                      <span className={`truncate`} >{suggestion.Ticker}</span>
                      <span className={`pr-3 italic text-xxs truncate max-w-full ${ active || suggestion.Ticker===ticker ? 'text-gray-200' : 'text-gray-500'} ${suggestion.Ticker===ticker ? '' : 'pl-4'}`}>
                        <CheckIcon className={`h-4 w-4 text-yellow-300 inline ${suggestion.Ticker===ticker ? '' : 'hidden'}`} aria-hidden="true" /> {suggestion.Name}
                      </span>
                    </div>
                  )}

                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>

      { showTickerInfo===false &&
        <span className="inline-flex items-center px-1 rounded-r-md border border-l-0 border-gray-500 bg-gradient-to-r from-blue-600 to-blue-800">
          <button
            type="button"
            className="mx-auto flex-shrink-0 flex items-center justify-center"
            onClick={() => updateShowTickerInfo(!showTickerInfo)}>
            <InformationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </span>
      }
      { showTickerInfo===true &&
        <span className="inline-flex items-center px-1 rounded-r-md border border-l-0 border-gray-500 bg-gradient-to-r from-red-600 to-red-800">
          <button
            type="button"
            className="mx-auto flex-shrink-0 flex items-center justify-center"
            onClick={() => updateShowTickerInfo(!showTickerInfo)}>
            <XCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </span>
      }

    </div> 
  );
}
export default TICKER_INPUT_WITH_INFO_BUTTON;
