import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import SIDE_MENU_MOBILE from './SIDE_MENU_MOBILE.js';
import SIDE_MENU from './SIDE_MENU.js';
import HEADER_MOBILE from './HEADER_MOBILE.js';
// import BETA_FOOTER from './BETA_FOOTER.js';
import SECTION_TITLE from './SECTION_TITLE.js';
import UNDER_MAINTENANCE from './UNDER_MAINTENANCE';
import LOGIN_REQUIRED from './LOGIN_REQUIRED';
import { useSelector, useStore } from 'react-redux';
import actions from '../state/actions';
import MODAL from "../components/MODAL";
import decodeJwt from "../bin/decodeJwt";
import Headers from "../bin/Headers";
import config from '../config.json';
import { useWebSocket } from './WEBSOCKET_CONTEXT';

const SITE_SKELETON = (props) => {
	const noChrome = props.noChrome,
		fullScreen = props.fullScreen,
		{ updateWebSocket } = useWebSocket(),
		[sidebarOpen, setSidebarOpen] = useState(false),
		[authRequired, setAuthRequired] = useState(false),
		[underMaintenance, setUnderMaintenance] = useState(false),
		[hasModal, setHasModal] = useState(false),
		[modalOpen, setModalOpen] = useState(false),
		[modalType, setModalType] = useState(null),
		[modalHeadline, setModalHeadline] = useState(null),
		[modalHtmlInformation, setModalHtmlInformation] = useState(null),
		[modalInformation, setModalInformation] = useState(null),
		[modalActionClose, setModalActionClose] = useState(null),
		store = useStore(),
		location = useLocation(),
		// version= useSelector(state => state.version),
		navigation = useSelector(state => state.navigation),
		token = useSelector(state => state.token),
		decodedToken = decodeJwt(token),
		level = decodedToken && decodedToken.level ? decodedToken.level : null;
  
	const handshakeWebsocket = async () => {
		// if (!token) { return; }
		updateWebSocket(token);
	};

	const checkUnseenAlerts = async () => {
		if (!token) { return; }
		let data = await fetch(`${config.api}/unseennoticecount`, { method: "GET", headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result ? d.result : null);
		if (!data && isNaN(data)) { return; }
		store.dispatch( actions.updateUnseenAlerts(data) );
	};

  const updateNavigationSettingInState= ()=>{
    if (!navigation || !navigation.length){ return; }
    let update_navigation= navigation.map( page=>{
      page.current= (page.href===location.pathname ? true : false);
      if (page.href===location.pathname){
        if (page.maintenance){
          setUnderMaintenance(page.maintenance);
        }else{
          setUnderMaintenance(false);
        }
        if (page.authrequired && !token){
          setAuthRequired(page.authrequired);
        }else{
          setAuthRequired(false);
        }
        if (page.modal){
          setHasModal( true );
          setModalOpen( page.modal.open ? page.modal.open : false );
          setModalType( page.modal.type ? page.modal.type : null );
          setModalHeadline( page.modal.headline ? page.modal.headline : null );
          setModalHtmlInformation( page.modal.htmlInformation ? page.modal.htmlInformation : null );
          setModalInformation( page.modal.information ? page.modal.information : null );
          setModalActionClose( page.modal.actionClose ? page.modal.actionClose : null );
        }else if (!page.modal){
          setHasModal( false );
          setModalOpen( false );
          setModalType( null );
          setModalHeadline( null );
          setModalHtmlInformation( null );
          setModalInformation( null );
          setModalActionClose( null );
        }
      }
      return page;
    });
    store.dispatch( actions.updateNavigation(update_navigation) );
	};

	// eslint-disable-next-line
	useEffect(() => { handshakeWebsocket(); checkUnseenAlerts(); updateNavigationSettingInState(); }, [location]);
  
  return (
    // <div className="h-screen flex overflow-hidden bg-gray-900">
    <div className="flex bg-gray-900">
      { (hasModal) ?
        <MODAL
          open={ modalOpen }
          setOpen={ setModalOpen }
          type={ modalType }
          headline={ modalHeadline }
          html_information={ modalHtmlInformation }
          information={ modalInformation }
          action_close={ modalActionClose } />
        :
        <></>
      }
      
			<SIDE_MENU_MOBILE
				// noChrome={noChrome}
				noChrome={true}
				navigation={navigation}
				sidebarOpen={sidebarOpen}
				setSidebarOpen={setSidebarOpen}
				token={token}
				level={level} />

			<SIDE_MENU
				noChrome={true}
				navigation={navigation}
				token={token}
				level={level} />

      <div className="flex flex-col w-0 flex-1 min-h-screen">

				<HEADER_MOBILE
					noChrome={true}
					token={token}
          setSidebarOpen={setSidebarOpen}
					level={level} />
        
        {/* <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none pb-6"> */}
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
					{/* <div className={`mt-16 ${noChrome ? '' : 'lg:hidden xl:hidden 2xl:hidden'}`}></div> */}
					<div className={`mt-16 ${true ? '' : 'lg:hidden xl:hidden 2xl:hidden'}`}></div>
					<div className={`${fullScreen ? '' : 'py-6'}`}>
            
            <SECTION_TITLE
              navigation={navigation}
              setModalOpen={setModalOpen} />

						<div className={`${fullScreen ? '' : 'max-w-7xl mx-auto px-4 sm:px-6 md:px-8'}`}>
						{/* <div className={`${fullScreen ? '' : 'max-w-7xl mx-auto px-4 sm:px-2'}`}> */}
              { (underMaintenance) ? <UNDER_MAINTENANCE /> :
                (authRequired) ? <LOGIN_REQUIRED /> :
                props.children
              }
            </div>
      
          </div>
				</main>
				
				{/* <BETA_FOOTER /> */}
      </div>
    </div>
  )
}

export default SITE_SKELETON
