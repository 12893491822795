import React from 'react';
import STOCKS_LARGEST_TRADES from "../components/STOCKS_LARGEST_TRADES";
import STOCKS_LARGEST_ORDERS from "../components/STOCKS_LARGEST_ORDERS";
import OPTIONS_LARGEST_TRADES from "../components/OPTIONS_LARGEST_TRADES";
import OPTIONS_NOTABLE_TRADES from "../components/OPTIONS_NOTABLE_TRADES";

const MAIN = (props) => {
	let ticker = props.ticker,
		open = props.open,
		high = props.high,
		low = props.low,
		close = props.close,
		last = props.last,
		volume = props.volume,
		decodedToken = props.decodedToken,
		optionVolume = props.optionVolume,
		topTrades = props.topTrades,
		topBidOrders = props.topBidOrders,
		topAskOrders = props.topAskOrders,
		orderBook = props.orderBook,
		orderBookTime = props.orderBookTime,
		tickerStats = props.tickerStats,
		selectedLargestTradeType = props.selectedLargestTradeType,
		topStockTradesByValue = props.topStockTradesByValue;
	
  return (
		<div className='w-full h-full p-1'>
			{selectedLargestTradeType === 'stock trades' && topStockTradesByValue ? <STOCKS_LARGEST_TRADES ticker={ticker} optionVolume={optionVolume} decodedToken={decodedToken} open={open} high={high} low={low} close={close} last={last} volume={volume} trades={topStockTradesByValue} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} orderBook={orderBook} orderBookTime={orderBookTime} tickerStats={tickerStats}  /> : <></>}
			{selectedLargestTradeType === 'bid orders' && topStockTradesByValue ? <STOCKS_LARGEST_ORDERS ticker={ticker} optionVolume={optionVolume} decodedToken={decodedToken} last={last} orders={topBidOrders} orderType='Bid' orderBook={orderBook} tickerStats={tickerStats}  /> : <></>}
			{selectedLargestTradeType === 'ask orders' && topStockTradesByValue ? <STOCKS_LARGEST_ORDERS ticker={ticker} optionVolume={optionVolume} decodedToken={decodedToken} last={last} orders={topAskOrders} orderType='Ask' orderBook={orderBook} tickerStats={tickerStats}  /> : <></>}
			{selectedLargestTradeType === 'binary options' && topTrades ? <OPTIONS_LARGEST_TRADES largesttype='value' ticker={ticker} trades={topTrades.TopOptionBinaryTradesByValue} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} /> : <></>}
			{selectedLargestTradeType === 'valuable options' && topTrades ? <OPTIONS_LARGEST_TRADES largesttype='value' ticker={ticker} trades={topTrades.TopOptionTradesByValue} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} /> : <></>}
			{selectedLargestTradeType === 'sizable options' && topTrades ? <OPTIONS_LARGEST_TRADES largesttype='size' ticker={ticker} trades={topTrades.TopOptionTradesBySize} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} /> : <></>}
			{selectedLargestTradeType === 'notable options' && topTrades ? <OPTIONS_NOTABLE_TRADES largesttype='value' ticker={ticker} trades={topTrades.NotableOptionTrades} indexDate={topTrades.IndexDate ? topTrades.IndexDate : null} /> : <></>}
		</div>
  );
}
export default MAIN;