import React, {Fragment, useEffect, useState, useRef} from 'react';
import config from "../config.json";
import Headers from "../bin/Headers";
import todaysDate from "../bin/todaysDate";
import TICKER_CHART_CHARTJS_V2 from "../components/TICKER_CHART_CHARTJS_V2.js";
import OUTPUT_NOTABLE_TRADE_SHARE from "../components/OUTPUT_NOTABLE_TRADE_SHARE.js";
import calculateTimeDifference from "../bin/calculateTimeDifference";
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from "@heroicons/react/24/solid";
import LOADING from "./LOADING";

const MODAL = (props) => {
	const token= useSelector(state => state.token);
	const data = props.data || null,
		open = props.open || false,
		hideshare = props.hideshare || false,
		setOpen = props.setOpen || null,
		[shareData, setShareData] = useState({
			UnderlyingTicker: data && data.UNDERLYING ? data.UNDERLYING : null,
			Type: data && data.TYPE ? data.TYPE : null,
			Strike: data && data.STRIKE ? data.STRIKE : null,
			ExpirationDateString: todaysDate(),
			Price: '',
			Size: '',
			EntryTime: '06:30:00.000',
			Copy: true
		}),
		[openInterest, setOpenInterest] = useState([]),
		[selectedTab, setSelectedTab] = useState('Open Interest'),
		[LoadingValue, setLoadingValue] = useState(true),
		// tabs = ["Chart", "Trade", "Open Interest", "Share"];
		// tabs = ["Chart", "Open Interest", "Share"];
		tabs = ["Open Interest", "Share"];
	const cancelButtonRef = useRef(null);
	const start = async () => {
		if (!data) { return; }
		setSelectedTab("Open Interest");
		setShareData({
			...shareData,
			UnderlyingTicker: data && data.UNDERLYING ? data.UNDERLYING : null,
			Type: data && data.TYPE ? data.TYPE : null,
			Strike: data && data.STRIKE ? data.STRIKE : null,
		});
		setLoadingValue(true);
		setOpenInterest([]);
		let headers = Headers(token);
		// let result = await fetch(`${config.api}/openinterest?ticker=${data.UNDERLYING}&optionTicker=${data.TICKER}`, { headers }).then(d => d.json()).then(d => d.result ? d.result : null);
		// let result = await fetch(`https://io.redstripedtie.com/ursa/openinterest?ticker=${data.UNDERLYING}&optionTicker=${data.TICKER}`, { headers }).then(d => d.json()).then(d => d.result ? d.result : null);
		let result = await fetch(`https://api.redstripedtie.com/openinterest?ticker=${data.UNDERLYING}&optionTicker=${data.TICKER}`, { headers }).then(d => d.json()).then(d => d.result ? d.result : null);
		setOpenInterest(result);
		setLoadingValue(false);
	};
	useEffect(() => { start(); }, [data]);

	const outputOpenInterest = (list) => {
		if (!list || !list.length || !data) { return; }
		let html = <ul className='w-100 m-0 p-0'>{
			(list).map((entry, idx) => <li key={idx} className='flex flex-row w-100'>
				<span className={`${entry.IndexDate===data.EntryDateString ? 'text-indigo-300 font-bold' : 'text-gray-400'} mr-1 text-left`}>{entry.IndexDate}:</span>
				<span className="flex-grow text-right">
					{entry.Snapshot[0].Change && entry.Snapshot[0].Change>0 ? <span className="text-emerald-400 px-1 py-0.5 bg-green-200 text-sm rounded-md mr-2">+{(entry.Snapshot[0].Change).toLocaleString()}</span> : <></>}
					{entry.Snapshot[0].Change && entry.Snapshot[0].Change<0 ? <span className="text-rose-400 px-1 py-0.5 bg-red-200 text-sm rounded-md mr-2">{(entry.Snapshot[0].Change).toLocaleString()}</span> : <></>}
					<span className={`${entry.IndexDate === data.EntryDateString ? 'text-indigo-300 font-bold' : 'text-gray-400'}`}>{entry.Snapshot[0].OpenInterest ? (entry.Snapshot[0].OpenInterest).toLocaleString() : 0}</span>
				</span>
			</li>)
		}</ul>;
		return html;
	};

	const outputTradeForm = (data) => {
		return (
			<div className="mt-2 w-full flex flex-col items-start justify-center">
				
				<span className="flex flex-row w-full items-start justify-between gap-1">
					<span className='font-light text-gray-400 w-100 text-left'>Entry Price:</span>
					<span className="ml-1 font-light w-100 text-right text-gray-800">
						<input type="text" value={shareData.Price} onChange={(evt) => { setShareData({ ...shareData, Price: evt.target.value }) }}	/>
					</span>
				</span>
				<span className="flex flex-row w-full items-start justify-between gap-1">
					<span className='font-light text-gray-400 w-100 text-left'>Entry Size:</span>
					<span className="ml-1 font-light w-100 text-right text-gray-800">
						<input type="text" value={shareData.Size} onChange={(evt) => { setShareData({ ...shareData, Size: evt.target.value }) }}	/>
					</span>
				</span>
				<span className="flex flex-row w-full items-start justify-between gap-1">
					<span className='font-light text-gray-400 w-100 text-left'>Entry Date:</span>
					<span className="ml-1 font-light w-100 text-right text-gray-800">
						<input type="text" value={shareData.ExpirationDateString} onChange={(evt) => { setShareData({ ...shareData, ExpirationDateString: evt.target.value }) }}	/>
					</span>
				</span>
				<span className="flex flex-row w-full items-start justify-between gap-1">
					<span className='font-light text-gray-400 w-100 text-left'>Entry Time:</span>
					<span className="ml-1 font-light w-100 text-right text-gray-800">
						<input type="text" value={shareData.EntryTime} onChange={(evt) => { setShareData({ ...shareData, EntryTime: evt.target.value }) }}	/>
					</span>
				</span>
			</div>
		);
	};

	const tabNavigation = () => {
		return (
			<div className='mb-5'>
				<div className="sm:hidden">
					<select onChange={(evt)=>{setSelectedTab(evt.target.value)}} className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500" defaultValue={tabs.find((tab) => tab===selectedTab)}>
						{tabs.filter((tab) => tab!=='Share'  || tab==='Share' && hideshare===false).map((tab) => ( <option key={tab}>{tab}</option> ))}
					</select>
				</div>
				<div className="hidden sm:block">
					<nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow">
						{tabs.filter((tab) => tab!=='Share'  || tab==='Share' && hideshare===false).map((tab, index) => (
							<button key={index}
								className={`group min-w-0 flex-1 overflow-hidden py-2 px-4 text-center text-sm font-medium ${selectedTab===tab ? 'bg-slate-300 text-gray-900' : 'bg-white text-gray-500'} ${index === 0 ? 'rounded-l-lg' : ''} ${index === tabs.length - 1 ? 'rounded-r-lg' : ''}`}
								onClick={()=>{setSelectedTab(tab)}}>
								{tab}
							</button>
						))}
					</nav>
				</div>
			</div>
		);
	};

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex justify-center items-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 md:items-start">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" >
						{/* <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"> */}
						<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full min-w-[310px] sm:p-6">
							<div className="absolute right-0 top-0 pr-4 pt-4 block">
								<button type="button" className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => setOpen(false)} >
									<XMarkIcon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<div className="mt-5 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
									Option Contract<br />
									<span className='flex flex-row gap-1 text-md'>
										{data && data.EXPIRATION ? <span className='bg-sky-500 text-sky-900 rounded px-2'>{data.EXPIRATION}</span> : ''}
										{data && data.UNDERLYING ? <span>{data.UNDERLYING}</span> : ''}
										{data && data.STRIKE ? <span>${data.STRIKE}</span> : ''}
										{data && data.TYPE ? <span className={`${data.TYPE === 'CALL' ? 'text-emerald-500' : (data.TYPE === 'PUT' ? 'text-emerald-500' : 'text-emerald-500')}`}>{data.TYPE}</span> : ''}
									</span>
								</Dialog.Title>
								<div className="mt-2">
									{ LoadingValue===true && 
										<div className='w-full'>
											<LOADING color="text-green-700" />
										</div>
									}
									{LoadingValue === false ? tabNavigation() : '' }
								</div>
								{LoadingValue === false && data && selectedTab === 'Chart' ? <TICKER_CHART_CHARTJS_V2 ticker={data.TICKER} entryprice={shareData.Price} entrysize={shareData.Size} entrydate={shareData.ExpirationDateString} starttime={shareData.EntryTime} charttiming="minute" /> : ''}
								{LoadingValue === false && data && selectedTab === 'Trade' ? outputTradeForm(data) : ''}
								{LoadingValue === false && openInterest && openInterest.length && selectedTab==='Open Interest' ? outputOpenInterest(openInterest) : '' }
								{LoadingValue === false && data && openInterest && openInterest.length && selectedTab === 'Share' ? <OUTPUT_NOTABLE_TRADE_SHARE data={shareData} auditResult={null} openInterest={openInterest} /> : ''}
							</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
export default MODAL;