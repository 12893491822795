import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Transition } from '@headlessui/react';
import config from '../config.json';
import Headers from "../bin/Headers";
import scrollToTop from "../bin/scrollToTop";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import LOADING from "../components/LOADING";
import { Link, useNavigate } from "react-router-dom";
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import { XCircleIcon } from '@heroicons/react/24/solid';

const WatchlistTickerEntryUi = ({ item, index, token, loadWatchlist, focusedTicker, setFocusedTicker }) => {
	// if (!item) { return <></>; }
	// if (!item.ticker) { return <></>; }
	const [loading, setLoading] = useState(false),
		navigate = useNavigate();
	// const handlers = useSwipeable({
  //   onSwipedRight: () => handleDeleteRequest(item.ticker, index),
  //   // Configure other options such as delta and preventDefaultTouchmoveEvent as needed
  //   delta: 10,
  //   preventDefaultTouchmoveEvent: true,
  // });

	// const removeFromWatchList = async (_ticker) => {
	// 	setLoading(true);
	// 	await fetch(`${config.api}/watchlist?ticker=${_ticker}`, { headers: Headers(token), method: "DELETE" });
	// 	loadWatchlist();
	// };
	
	const handleDeleteRequest = async (_ticker, _index) => {
		alert(`handleDeleteRequest: ${_ticker} ${_index}`);
		// setLoading(true);
		// await fetch(`${config.api}/watchlist?ticker=${_ticker}`, { headers: Headers(token), method: "DELETE" });
		// loadWatchlist();
	};

	const focusOnTicker = (_ticker) => {
		//on mobile, redirect to the ticker page
		let mobileScreen = (window.innerWidth < 640) ? true : false;
		if (mobileScreen || focusedTicker===_ticker){
			//redirect to the ticker page
			return navigate(`/_/${_ticker}`);
		}
		setFocusedTicker(_ticker);
		scrollToTop();
	};

	return (
		// <div key={index} className={`w-full h-12 px-2 py-2 ${focusedTicker===item.ticker ? 'bg-gray-950' : ''}`} {...handlers}>
		<div key={index} className={`w-full h-12 px-2 py-2 ${focusedTicker===item.ticker ? 'bg-gray-950' : ''}`}>
			{loading ? <LOADING /> : <></>}
			{!loading ?
				<button onClick={()=>focusOnTicker(item.ticker)} className={`flex flex-row items-center w-full`}>
					<span className={`text-sm font-bold w-[60px] text-gray-100 flex gap-1 justify-start items-center`}>
						<span>{item.ticker ? item.ticker : '-'}</span>
					</span>
					
					<div className="flex flex-grow justify-center items-center">
						<div className='w-[70px]'>
              {/*<Sparklines data={item.sparklineData}>
								<SparklinesLine color={item.sparklineColor} />
							</Sparklines>*/}
						</div>
					</div>
						
					<div className='w-[100px] flex flex-col gap-1 items-end justify-end'>
						{item.percentChange ? <span className={`w-[60px] h-[20px] inline-flex justify-center items-center rounded-sm px-1.5 py-0 text-xxs leading-tight font-medium ${item.percentChange > 0 ? 'text-green-800 bg-green-300' : ''}${item.percentChange < 0 ? 'text-red-800 bg-red-300' : ''}${item.percentChange === 0 ? 'text-gray-800 bg-gray-300' : ''}`}>{item.percentChange > 0 ? '+' : (item.percentChange < 0 ? '' : '')}{item.percentChange}%</span> : <span className={`w-[60px] h-[20px] inline-flex justify-center items-center rounded-sm px-1.5 py-0 text-xxs leading-tight font-medium text-gray-800 bg-gray-300`}>0.00%</span> }		
						{item.last ? <span className={`text-xxs leading-tight font-light text-gray-400 mr-1`}>${ (Number(truncateToTwoDigits(item.last))).toLocaleString() }</span> : <></>}		
					</div>
				</button>
			: <></>}

			{/* {!loading ? <span className="absolute top-1 right-0 flex items-center px-1">
				<button
					type="button"
					className="mx-auto flex-shrink-0 flex items-center justify-center"
					onClick={() => removeFromWatchList(item.ticker)}>
					<XCircleIcon className="h-5 w-5 text-rose-700" aria-hidden="true" />
				</button>
			</span> : <></>} */}
		</div>
  );
};

export default WatchlistTickerEntryUi;